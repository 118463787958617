import { withCompController } from '@wix/editor-elements-integrations';
import {
  ISlideShowContainerControllerProps,
  SlideShowContainerProps,
  ISlideShowContainerStateValues,
  ISlideShowContainerMapperProps,
} from '../SlideShowContainer.types';

export default withCompController<
  ISlideShowContainerMapperProps,
  ISlideShowContainerControllerProps,
  SlideShowContainerProps,
  ISlideShowContainerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;
  const { reducedMotion } = stateValues;

  return {
    ...mapperProps,
    reducedMotion,
    changeSlide: (slideIndex: number) => {
      updateProps({
        currentSlideIndex: slideIndex,
      });
    },
    play: () => {
      updateProps({
        isPlaying: true,
      });
    },
    pause: () => {
      updateProps({
        isPlaying: false,
      });
    },
  };
});
